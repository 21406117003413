* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #000000aa;
}

html {
  margin: 0;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.paperDiv {
  padding: 1rem;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logoNilko {
  width: 10rem;
  display: inline-block;
}
.logoLockyt {
  width: 6rem;
  display: inline-block;
  margin-bottom: 3.5%;
  margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
  .logoLockyt {
    margin-top: 3.5%;
  }
}

.logosDiv {
  flex-direction: row;
}

.downloadBtn {
  background-color: #003781;
  color: #003781;
}
.linkDownload {
  text-decoration: none;
}

.logosContainer {
  flex-direction: row;
  width: 50%;
  justify-content: center;
  text-align: center;
}
